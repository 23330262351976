// @flow strict
import React from 'react';
import styles from './Content.module.scss';
import moment from 'moment';

type Props = {
  body: string,
  title: string,
  canonical: string
};

const Content = ({ body, title, canonical, date }: Props) => (
  
  <div className={styles['content']}>
    {canonical != null ? <link rel="canonical" href={canonical}></link> : <></>}
    
    <h1 className={styles['content__title']}>{title}</h1>
    <center><time className={styles['content__body']}>{moment(date).format('MMMM YYYY')}</time></center>
    

    <div className={styles['content__body']} dangerouslySetInnerHTML={{ __html: body }} />
  </div>
);

export default Content;
